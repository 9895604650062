import axios from "axios";
let token = "f2010a0b786961007e4bd59523dfafbd748d9a7c";
export const configHeader = {
  headers: {
    Authorization: "Bearer " + token,
  },
};
export const axiosInstance = axios.create({
  baseURL: "https://workspace.mvc.com.vn",
  headers: {
    Authorization: "Bearer " + token,
  },
});
export const setAuthorization = (token) => {
  axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };
};
axiosInstance.interceptors.response.use(
  function (response) {
    if (response.data.data && response.data.data.meta) {
      if (!response.data.meta) {
        if (response.data.meta.status_code === 403) {
          localStorage.clear();
          window.location.assign("/login");
        }
        return Promise.reject(response.data.meta.message);
      }
    }
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
