import PLACE from "../../assets/image/place.svg";
import MONEY from "../../assets/image/money.svg";
import DEADLINE from "../../assets/image/deadline.svg";
import { useNavigate } from "react-router";
import { formatDate, numberWithCommas } from "../../utils/format/format";
import { compareDate } from "../../services/apiService/checkDate";
import { useEffect, useState } from "react";

export default function ItemList({ data, id }) {
  console.log(data);
  const nagative = useNavigate();
  const handleRangeForm = (id) => {
    nagative("/job/" + id);
  };
  const [check_date, set_check_date] = useState(true);
  useEffect(() => {
    let check = compareDate(
      data?.expire_date === "" ? "2060-10-31" : data?.expire_date
    );
    set_check_date(check);
  }, []);
  return (
    <div
      onClick={
        data?.meta_slug === id
          ? null
          : check_date
          ? () => handleRangeForm(data.meta_slug)
          : null
      }
      className="hover:cursor-pointer grid grid-cols-1 sm:grid-cols-2 m-2 list-border mb-7"
    >
      <div className="col-span-1 m-2 ">
        <img
          src={data?.banner_url}
          className="sm:w-11/12 w-full lg:max-w-[300px]  rounded-lg"
        />
      </div>
      <div className=" col-span-1">
        <div className="ml-5 sm:ml-0 sm:mt-5">
          <div className="font-regular text-[15px]">{data?.job_position}</div>
          <div className="flex mt-2">
            <img src={PLACE} className="w-3" />
            <div className="ml-2 description-list font-book">
              {data?.location}
            </div>
          </div>
          <div className="flex mt-2">
            <img src={DEADLINE} className="w-3" />
            <div className="ml-2 description-list font-book">
              Hạn nộp:{" "}
              {data?.expire_date === "" ? (
                ""
              ) : check_date ? (
                formatDate(data?.expire_date)
              ) : (
                <span className="text-red-500">Hết hạn</span>
              )}
            </div>
          </div>
          <div className="flex mt-2">
            <img src={MONEY} className="w-3" />
            <div className="ml-2 description-list font-book">
              {data.salary_to === 0
                ? "Thương lượng"
                : numberWithCommas(data.salary_from) +
                  " - " +
                  numberWithCommas(data.salary_to)}
            </div>
          </div>
        </div>
        <div className="flex mt-3 justify-center md:mt-8 lg:mt-14 sm:justify-end">
          <button
            className="btn-ut bg-[#3AA3A4] text-[15px] px-6 py-2 text-white rounded-3xl"
            style={
              data?.meta_slug === id
                ? { backgroundColor: "#b4b4b4" }
                : check_date
                ? { backgroundColor: "#3AA3A4" }
                : { backgroundColor: "#b4b4b4" }
            }
            onClick={
              data?.meta_slug === id
                ? null
                : check_date
                ? () => handleRangeForm(data.meta_slug)
                : null
            }
          >
            Ứng tuyển
          </button>
        </div>
      </div>
    </div>
  );
}
