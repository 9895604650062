import { MetaTags } from "react-meta-tags";
export default function ContentPage({ jobDetail }) {
  return (
    <>
      <MetaTags>
        <title>{jobDetail?.data?.job_position}</title>
        <meta name="description" content={jobDetail?.data?.meta_description} />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" />
        <meta
          property="og:description"
          content={jobDetail?.data?.meta_description}
        />
      </MetaTags>
      <div className="col-span-1 lg:col-span-4  ">
        <div className="flex justify-center  ">
          <img
            src={jobDetail?.data?.banner_detail_url}
            className="rounded-lg px-5 sm:px-0"
          />
        </div>

        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <label>MÔ TẢ CÔNG VIỆC</label>
          <div
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.job_description,
            }}
            className="ca font-gothamBook description-sale mt-2 px-5 div-ne"
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <label>YÊU CẦU CÔNG VIỆC</label>
          <div
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.job_requirement,
            }}
            className="ca font-gothamBook description-sale mt-2 px-5 div-ne"
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <label>QUYỀN LỢI</label>
          <div
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.benefit,
            }}
            className="ca font-gothamBook description-sale mt-2 px-5 div-ne"
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <label>THỜI GIAN LÀM VIỆC</label>

          <div
            className="ca font-gothamBook description-sale mt-2 px-5 list-disc div-ne"
            dangerouslySetInnerHTML={{
              __html: jobDetail?.data?.working_time,
            }}
          ></div>
        </div>
        <div className="pl-6 mt-7 font-gothamRegular title-header">
          <label>LIÊN HỆ</label>
          <div
            className="ca font-gothamBook description-sale mt-2 px-5 list-disc div-ne"
            dangerouslySetInnerHTML={{
              __html:
                jobDetail?.data?.contact === false
                  ? ""
                  : jobDetail?.data?.contact,
            }}
          ></div>
        </div>
      </div>
    </>
  );
}
