import { axiosInstance } from "./configURL";

export const citiesService = {
  getDistrict: (province_id) => {
    return axiosInstance.get(`/base/district?city_id=${province_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCities: (data) => {
    return axiosInstance.get(
      "/base/city",

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getWards: (district_id) => {
    return axiosInstance.get(`/base/ward?district_id=${district_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
