import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import Compressor from "compressorjs";
import Modal from "react-modal";
import { Label } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import UPLDOAD from "../../assets/image/upload.jpg";
import SUCCESS from "../../assets/image/success.png";
import { jobServices } from "../../services/apiService/jobServices";
import {
  OCR_ENDPOINT,
  URL_API_UPLOAD_GCS,
} from "../../services/apiService/InterfaceExport";
import { apiNodeJS } from "../../services/apiService/apiNode";

const customStylesPopup = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#E2E2E2",
    border: "0px",
    maxWidth: "520px",
    height: "350px",
    width: "90%",
    zIndex: "999",
  },
};

export default function Three(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [errFile, setErrFile] = useState(null);
  const [imageFile, setimageFile] = useState(undefined);
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status_success, setStatusSuccess] = useState(false);
  const [statusCode, setStatusCode] = useState();
  console.log(statusCode);
  const params = useParams();
  const handleLastStep = () => {
    setLoading(true);
    if (imageFile === undefined) {
      const cv = {
        cv_url: "",
        job_id: params.id,
      };
      const result = JSON.parse(localStorage.getItem("Data_Object"));
      const resultPage3 = Object.assign(result, cv);
      jobServices
        .applyJob(resultPage3)
        .then((res) => {
          console.log(res);
          setLoading(false);
          if (res.result.meta.status_code === 200) {
            setSuccess(res.result.meta.message);
            setStatusSuccess(true);
            setIsOpen(true);
            setStatusCode(res?.result.meta?.status_code);
          } else {
            setSuccessTitle("Thất bại");
            setSuccess(res.result.meta.message);
            setStatusCode(res?.result.meta?.status_code);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setIsOpen(true);

      uploadCVToGCS();
    }
    props.lastStep();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let refInputUpload = useRef(null);

  const handleBack = () => {
    props.previousStep();
  };
  const [success, setSuccess] = useState("");
  const [successTitle, setSuccessTitle] = useState("Thành Công");

  const uploadCVToGCS = () => {
    setLoading(true);
    let formDataGCS = new FormData();
    formDataGCS.append("file", imageFile);
    formDataGCS.append(
      "fileName",
      uuid() +
        "_" +
        format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
        "_" +
        imageFile.name
    );
    formDataGCS.append("ocrBase", OCR_ENDPOINT);

    apiNodeJS
      .postUploadToNode(URL_API_UPLOAD_GCS, formDataGCS)
      .then((res) => {
        console.log(res);
        const cv = {
          cv_url: res.public_url,
          job_id: params.id,
        };
        const result = JSON.parse(localStorage.getItem("Data_Object"));
        const resultPage3 = Object.assign(result, cv);
        console.log(resultPage3);
        jobServices
          .applyJob(resultPage3)
          .then((res) => {
            console.log(res);
            setLoading(false);
            if (res.result.meta.status_code === 200) {
              setStatusSuccess(true);
              setSuccess(res.result.meta.message);
              setStatusCode(res?.result.meta?.status_code);
            } else {
              setSuccessTitle("Thất bại");
              setSuccess(res.result.meta.message);
              setStatusCode(res?.result.meta?.status_code);
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeImage = (event) => {
    try {
      setErrFile(null);
      let fileUploaded = event.target.files[0];
      const fileUploadedSize = fileUploaded.size / 1024 / 1024;
      let iSize = fileUploaded.size / 1024;
      if (iSize / 1024 > 1) {
        if (iSize / 1024 / 1024 > 1) {
          iSize = Math.round((iSize / 1024 / 1024) * 100) / 100;
          setimageFile(undefined);
          setErrFile(" Vui lòng chọn file < 5MB");
        } else {
          iSize = Math.round((iSize / 1024) * 100) / 100;
          if (iSize < 5) {
            if (fileUploadedSize > 20) {
              new Compressor(fileUploaded, {
                quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
              new Compressor(fileUploaded, {
                quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
              new Compressor(fileUploaded, {
                quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
              new Compressor(fileUploaded, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else {
              setimageFile(fileUploaded);
            }
          } else {
            setimageFile(undefined);
            setErrFile(" vui lòng chọn ảnh hoặc file < 5MB");
          }
        }
      } else {
        iSize = Math.round(iSize * 100) / 100;
        if (fileUploadedSize > 20) {
          new Compressor(fileUploaded, {
            quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
          new Compressor(fileUploaded, {
            quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
          new Compressor(fileUploaded, {
            quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
          new Compressor(fileUploaded, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else {
          setimageFile(fileUploaded);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  function closeModal() {
    setIsOpen(true);
  }
  const handleRedirectHome = () => {
    navigator("/");
  };
  return (
    <div>
      <div className="mx-32">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStylesPopup}
          ariaHideApp={true}
          allowFullScreen={true}
          contentLabel="Popup name"
        >
          {loading ? (
            <>
              <div className=" flex flex-col justify-center items-center border-solid mt-24  ">
                <div className="lds-roller flex justify-center items-center">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div id="scroll-body">
                <div className="relative flex flex-col items-center border-solid mt-6 scroll-body">
                  {statusCode !== 200 ? (
                    <></>
                  ) : (
                    <div className="pyro ">
                      <div className="before"></div>
                      <div className="after"></div>
                    </div>
                  )}

                  <div
                    className={`${
                      statusCode !== 200 ? "text-[#c43838]" : "text-[#38c44f]"
                    } font-gothamRegular text-[30px]  bg-popup-success text-center`}
                  >
                    {successTitle}
                  </div>
                  <div className="mt-12 font-gothamRegular text-[24px] text-center">
                    {status_success ? (
                      <div>
                        <img src={SUCCESS} />
                      </div>
                    ) : null}
                  </div>

                  <button
                    className="hover:cursor-pointer mt-12 mb-10 bg-footer p-2 rounded-md text-white"
                    onClick={handleRedirectHome}
                  >
                    Quay về trang chủ
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal>
        {modalIsOpen ? (
          <div className="font-gothamBold text-center my-40 background-hide"></div>
        ) : (
          <>
            <div className="flex flex-col items-center justify-center mt-10">
              {imageFile ? (
                <div className="flex flex-col items-center justify-center">
                  {imageFile.name}
                  <a
                    href={URL.createObjectURL(imageFile)}
                    className="bg-[#3AA3A4] my-2 px-2 rounded-md"
                    download
                  >
                    Preview
                  </a>
                </div>
              ) : null}
            </div>
            <div className="text-red-500 text-center">{errFile}</div>
            <div className="flex justify-center -ml-5 sm:ml-0 mt-3 w-full">
              <div
                className="flex justify-center items-center "
                htmlFor="nav-input"
              >
                <img src={UPLDOAD} className="w-14 " />
              </div>
              <input
                hidden
                type="file"
                label="hello"
                id="nav-file"
                onChange={(e) => handleChangeImage(e)}
                ref={refInputUpload}
                accept="image/*,.doc, .docx,.ppt, .pdf"
              />

              <Label
                htmlFor="nav-file"
                className="hover:cursor-pointer img-avatar italic cursor-pointer flex justify-center items-center "
              >
                Tải lên CV của ban ( Hỗ trợ .jpg, .pdf, .jpeg, .docx, .doc)
              </Label>
            </div>
          </>
        )}
      </div>
      {modalIsOpen ? (
        ""
      ) : (
        <div className="flex justify-around mt-10">
          <button
            className="hover:cursor-pointer w-36 h-9 btn-enable flex justify-center items-center cursor-pointer"
            onClick={handleBack}
          >
            Quay lại
          </button>
          <div
            className="hover:cursor-pointer w-36 h-9 btn-enable flex justify-center items-center cursor-pointer"
            onClick={handleLastStep}
          >
            Tiếp theo
          </div>
        </div>
      )}
    </div>
  );
}
