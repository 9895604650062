import { SET_JOB_DATA, SET_JOB_OFFICIAL } from "../Constant/reduxConstants";

export const setJobData = (data) => {
  return {
    type: SET_JOB_DATA,
    payload: data,
  };
};

export const setJobOfficial = (data) => {
  return {
    type: SET_JOB_OFFICIAL,
    payload: data,
  };
};
