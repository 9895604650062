import { dataNews } from "../../utils/data";

export default function PopupInfor({ index, set_open_popup }) {
  return (
    <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 " id="modal">
      <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className="absolute inset-0 bg-gray-900 opacity-70"
            onClick={() => set_open_popup(false)}
          />
          <div className="content-popup-condition">
            <div className=" content-popupgame p-3 rounded-sm">
              <div className="font-regular text-[#13a5a5] py-3">
                {dataNews[index].title}
              </div>
              <div>
                <img src={dataNews[index].img} className="rounded-lg" />
              </div>
              <div className="text-[13px] font-book py-3">
                {dataNews[index].content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
