import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { compareDate } from "../../../services/apiService/checkDate";
import { formatDate, numberWithCommas } from "../../../utils/format/format";
import HANNOP from "../../../assets/image/hannop.png";
import PROFILE from "../../../assets/image/location.png";
import MONEY from "../../../assets/image/money.png";

export default function ItemJobSession({ item }) {
  const navigate = useNavigate();
  const handleRangeForm = (job_id) => {
    if (check_date) {
      navigate("/job/" + job_id);
    } else {
    }
  };
  const [check_date, set_check_date] = useState(true);
  useEffect(() => {
    let check = compareDate(
      item?.expire_date === "" ? "2060-10-31" : item?.expire_date
    );
    set_check_date(check);
  }, []);

  return (
    <div
      className="list-border  hover:cursor-pointer col-span-1 px-2 rounded-md shadow-grid relative"
      onClick={(e) => handleRangeForm(item.meta_slug)}
    >
      <div>
        {item?.banner_url ? (
          <img
            src={item?.banner_url}
            className=" mt-2 rounded-md"
            alt="image"
          />
        ) : (
          ""
        )}
      </div>
      <div className="mt-2 ">
        <div className=" text-[13px] sm:text-[15px] sm:mt-3 md:mt-1 min-[1300px]:mt-0 font-regular uppercase h-10 sm:h-12">
          {item?.job_position}
        </div>
        <div>
          <div className="flex items-center w-full mt-2">
            <div>
              <img src={PROFILE} className="w-4 mr-2" />
            </div>
            <div className="text-[12px] sm:text-[14px] font-book text-left">
              {item?.location}
            </div>
          </div>

          <div className="flex items-center w-full mt-2">
            <div>
              <img src={HANNOP} className="w-4 mr-2" />
            </div>
            <div className="text-[12px] sm:text-[14px] font-book text-left">
              Hạn nộp:{" "}
              <span
                className="text-[10px] sm:text-[14px]"
                style={check_date ? { color: "black" } : { color: "red" }}
              >
                {item?.expire_date === ""
                  ? ""
                  : check_date
                  ? formatDate(item?.expire_date)
                  : "Hết hạn nộp"}
              </span>
            </div>
          </div>

          <div className="flex items-center w-full mt-2">
            <div>
              <img src={MONEY} className="w-4 mr-2" />
            </div>
            <div className="text-[11px] sm:text-[14px] font-book text-left">
              {item?.salary_to === 0
                ? "Thương lượng"
                : numberWithCommas(item?.salary_from) +
                  " - " +
                  numberWithCommas(item?.salary_to)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-10 min-[1024px]:mb-16 ">
        <button
          className="absolute bottom-0 btn-ut mb-5 min-[1024px]:mb-8 font-semibold text-[14px] bg-[#3AA3A4] sm:mt-3 min-[1300px]:mt-0 sm:px-8 py-2 px-4 text-white rounded-3xl "
          style={
            check_date
              ? { backgroundColor: "#3AA3A4" }
              : { backgroundColor: "#b4b4b4" }
          }
          onClick={check_date ? () => handleRangeForm(item.meta_slug) : null}
        >
          Ứng tuyển
        </button>
        <br />
      </div>
    </div>
  );
}
