import Header from "../../components/Header";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import NewsItem from "./NewsItem";
import { dataRecruitmentNews } from "../../utils/data";

export default function NewDetails() {
  const { id } = useParams();
  const dataFilter = dataRecruitmentNews.filter((x) => x.id === id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="lg:mt-20 mt-14">
        <Header title={dataFilter[0].title} />
      </div>
      <NewsItem data={dataFilter[0]} />
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </div>
  );
}
