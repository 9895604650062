import LOGO_MVC from "../../assets/image/logo-mvc.png";
import ABOUT_US from "../../assets/image/about.svg";
import CONTACT from "../../assets/image/contact.svg";
import WORK from "../../assets/image/work.svg";
import SERVICE from "../../assets/image/service.svg";
import FACEBOOK from "../../assets/image/face.gif";
import ZALO from "../../assets/image/zalo.png";
import LINKEDIN from "../../assets/image/link.gif";
import BORDER from "../../assets/image/ngang.jpg";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import ICON_NAV from "../../assets/image/icon_nvc.png";
import CART from "../../assets/image/bx-cart.svg";

export default function Header({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const handleRedirect = (redirect) => {
    window.open(redirect, "_blank");
  };
  const handleFirst = () => {
    navigate("/");
  };
  const handleSecond = () => {
    navigate("/list-jobs/1");
  };
  const handleThird = () => {
    navigate("/recruitment-news");
  };
  const handleFour = () => {
    navigate("/contact");
  };

  return (
    <div className="nav">
      <nav className="nav__pc ">
        <div
          className="logo_mvc hover:cursor-pointer"
          onClick={handleFirst}
        ></div>
        <ul className="nav__list">
          <li className="border-title flex flex-col  justify-center">
            {location?.pathname === "/" ? (
              <>
                <a className="w-28 text-title nav__link font-gothamBook text-center mt-1">
                  Trang chủ
                </a>
                <div>
                  <img src={BORDER} className="w-16 h-1 ml-6 mt-1" />
                </div>
              </>
            ) : (
              <a
                onClick={handleFirst}
                className="w-28 border-title  nav__link font-gothamBook text-center"
              >
                Trang chủ
              </a>
            )}
          </li>
          <li className=" border-title flex flex-col  justify-center">
            {location?.pathname.includes("/list-jobs") ? (
              <>
                <a className="w-48 text-title nav__link font-gothamBook mt-1">
                  Cơ hội - Nghề nghiệp
                </a>
                <div>
                  <img src={BORDER} className="w-16 h-1 ml-14 mt-1" />
                </div>
              </>
            ) : (
              <a
                onClick={handleSecond}
                className="w-48 border-title nav__link font-gothamBook"
              >
                Cơ hội - Nghề nghiệp
              </a>
            )}
          </li>
          <li className="border-title flex flex-col  justify-center">
            {location?.pathname === "/recruitment-news" ? (
              <>
                <a className="w-40 text-title nav__link font-gothamBook mt-1">
                  Tin tức - Sự kiện
                </a>
                <div>
                  <img src={BORDER} className="w-16 h-1 ml-9 mt-1" />
                </div>
              </>
            ) : (
              <a
                onClick={handleThird}
                className="w-40 border-title nav__link font-gothamBook"
              >
                Tin tức - Sự kiện
              </a>
            )}
          </li>
          <li className="border-title flex flex-col  justify-center">
            {location?.pathname === "/contact" ? (
              <>
                <a className="w-16 text-title nav__link font-gothamBook mt-1">
                  Liên hệ
                </a>
                <div>
                  <img src={BORDER} className="w-14 h-1 ml-[2px] mt-1" />
                </div>
              </>
            ) : (
              <a
                onClick={handleFour}
                className="w-16 border-title nav__link font-gothamBook"
              >
                Liên hệ
              </a>
            )}
          </li>
        </ul>
        <button className="flex flex-col bg-[#3AA3A4] justify-center">
          <a
            onClick={handleFour}
            href="https://banhangluudong.mvcpro.vn"
            target="_blank"
            className="w-44 text-[14px] absolute top-1/2 bg-cart -translate-y-1/2 right-10 font-gothamRegular text-[#333333] rounded-lg leading-5 bg-[#FFFF63] py-2 px-3"
          >
            Chương Trình Bán Hàng Lưu Động
          </a>
        </button>
      </nav>
      <img
        src={LOGO_MVC}
        className="logo_mvc-child-mobile hover:cursor-pointer"
        onClick={handleFirst}
      />
      <label htmlFor="nav-mobile-input" className="nav_bars-btn ">
        <img src={ICON_NAV} className="w-12" />
      </label>

      <input
        type="checkbox"
        hidden
        name=""
        className="nav_input"
        id="nav-mobile-input"
      />
      <label htmlFor="nav-mobile-input" className="nav_overlay"></label>

      <nav className="nav__mobile">
        <img src={LOGO_MVC} className="logo_mvc-nav " />
        <ul className="grid grid-cols-1 mt-[36%] ">
          <li
            className="h-[75px] w-full pl-[12%] flex justify-start items-center li-navbar"
            onClick={handleFirst}
          >
            <span>
              <img src={ABOUT_US} />
            </span>
            <div
              href=""
              className="ml-[7%] text-left col-span-1 font-gothamBook"
            >
              Trang chủ
            </div>
          </li>
          <li
            className="h-[75px] pl-[12%] flex justify-start items-center li-navbar"
            onClick={handleSecond}
          >
            <span>
              <img src={SERVICE} />
            </span>
            <div
              href=""
              className="ml-[7%] text-left col-span-1 font-gothamBook"
            >
              Cơ hội - Nghề nghiệp
            </div>
          </li>
          <li
            className="h-[75px] pl-[12%] flex justify-start items-center li-navbar"
            onClick={handleThird}
          >
            <span>
              <img src={WORK} />
            </span>
            <div
              href=""
              className="ml-[7%] text-left col-span-1 font-gothamBook"
            >
              Tin tức - Sự kiện
            </div>
          </li>
          <li
            className="h-[75px] pl-[12%] flex justify-start items-center li-navbar mb-1"
            onClick={handleFour}
          >
            <span>
              <img src={CONTACT} />
            </span>
            <div
              href=""
              className="ml-[7%] text-left col-span-1 font-gothamBook"
            >
              Liên hệ
            </div>
          </li>
          <li
            className="h-[75px] pl-[12%] flex justify-start items-center mb-1"
            onClick={handleFour}
          >
            {/* <span> */}
            <img src={CART} alt="Image" className="relative z-50 w-6" />
            {/* </span> */}

            <a
              href="https://banhangluudong.mvcpro.vn"
              target="_blank"
              className="ml-[7%] text-left col-span-1 text-[#3AA3A4] font-gothamBook"
            >
              Chương trình bán hàng lưu động
            </a>
          </li>
        </ul>
        <div className="flex justify-center text-black mb-8 mt-2">
          <div
            onClick={() => handleRedirect("https://www.facebook.com/mvcpro.vn")}
          >
            <img src={FACEBOOK} className="w-10" />
          </div>
          <div
            onClick={() =>
              handleRedirect("https://zalo.me/2069335117770581954")
            }
          >
            <img src={ZALO} className="ani-icon-zalo w-10 mx-5" />
          </div>
          <div
            onClick={() =>
              handleRedirect(
                "https://www.linkedin.com/company/mvcagency/about/"
              )
            }
          >
            <img src={LINKEDIN} className="w-10" />
          </div>
        </div>
      </nav>
    </div>
  );
}
