import TYPE from "../../assets/image/icon-type.png";
import LOCATION from "../../assets/image/location.png";
import MONEY from "../../assets/image/money.png";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { citiesService } from "../../services/apiService/citiesService";
import { jobServices } from "../../services/apiService/jobServices";

export default function NavSearch({ setJob }) {
  const [type_job, setTypeJob] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [cities, setCities] = useState([]);
  const navigator = useNavigate();
  const onhandleType = (e) => {
    setTypeJob(e.target.value);
  };
  const onhandleCity = (e) => {
    setLocation(e.target.value);
  };
  const onhandleSalary = (e) => {
    setSalary(e.target.value);
  };

  const handleSearch = () => {
    const url = `?location_id=${location}&salary_from=${salary}&job_type_id=${type_job}`;
    jobServices
      .getJob(url)
      .then((res) => {
        navigator("/search" + url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const typeJob = useQuery({
    queryKey: ["getTypeJob"],
    queryFn: () => jobServices.getJobType(),
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    const data = {};
    citiesService
      .getCities(data)
      .then((cities) => {
        setCities(cities.data);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className="relative menu-carousel w-[90%] sm:w-[65%] min-[1700px]:w-[55%] min-[1024px]:w-[90%] min-[1300px]:w-[73%]">
      <div className=" py-5 px-5 grid gap-5 sm:grid-cols-4 grid-cols-1">
        <div className="col-span-1 flex items-center bg-[#ffffff] ">
          <div>
            <img src={TYPE} />
          </div>
          <select
            id="countries"
            value={type_job || "-1"}
            onChange={(e) => onhandleType(e)}
            className="bg-[#dcf7f7] mx-2 h-9 text-[#202020] text-sm rounded-lg focus:border-[#3BA3A5] ds-select  block w-full px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" className=" ">
              Chọn loại công việc
            </option>
            {typeJob?.data?.data?.map((res) => {
              return (
                <option key={res.job_type_id} value={res.job_type_id}>
                  {res.job_type}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-span-1 flex items-center">
          <div>
            <img src={LOCATION} />
          </div>
          <select
            id="countries"
            onChange={(e) => onhandleCity(e)}
            value={location || "-1"}
            className="bg-[#dcf7f7] mx-2 h-9 text-gray-900 text-sm rounded-lg focus:border-[#3BA3A5] ds-select  block w-full px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">Địa điểm làm việc</option>
            {cities?.map((res) => (
              <option key={res?.city_id} value={res.city_id}>
                {res?.city}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-1 flex items-center">
          <div>
            <img src={MONEY} />
          </div>
          <select
            id="salary"
            onChange={(e) => onhandleSalary(e)}
            value={salary || "-1"}
            className="bg-[#dcf7f7] mx-2 h-9 text-gray-900 text-sm rounded-lg focus:border-[#3BA3A5] ds-select  block w-full px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">Mức lương</option>
            <option key={3000000} value={3000000}>
              Từ 3.000.000
            </option>
            <option key={5000000} value={5000000}>
              Từ 5.000.000
            </option>
            <option key={7000000} value={7000000}>
              Từ 7.000.000
            </option>
            <option key={10000000} value={10000000}>
              Từ 10.000.000
            </option>
            <option key={12000000} value={12000000}>
              Từ 12.000.000
            </option>
            <option key={15000000} value={15000000}>
              Từ 15.000.000
            </option>
            <option key={1700000} value={17000000}>
              Từ 17.000.000
            </option>
          </select>
        </div>
        <div className="col-span-1 flex items-center">
          <button className="w-full btn-search py-8" onClick={handleSearch}>
            Tìm kiếm
          </button>
        </div>
      </div>
      {/* <div>hello</div> */}
    </div>
  );
}
