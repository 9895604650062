export default function ItemBrand({ data }) {
  return (
    <div className="max-w-[350px] w-[150px] content-main h-[80px]  relative flex justify-center ">
      <div className="max-h-[60px] h-[60px]">
        <img
          src={data.img}
          id="img-slick"
          className="left-1/2 -translate-x-1/2 rounded-xl h-[40px] shadow-img relative top-1 flex items-center justify-center w-full "
        />
      </div>
    </div>
  );
}
