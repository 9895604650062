import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import { useDispatch } from "react-redux";
import { setJobData, setJobOfficial } from "../../Redux/Action/userAction";
import { jobServices } from "../../services/apiService/jobServices";
import {
  jobDataLocal,
  jobDataOfficial,
} from "../../services/localService/localService";
import "./PageMain.css";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import Header from "../../components/HeaderNavBar/HeaderNavBar";
import NavSearch from "../../components/NavSearch/NavSearch";
import ChartBenefits from "./ChartBenefits/ChartBenefits";
import NewsMainList from "./NewsMainList/NewsMainList";
import ItemJobSession from "./ListJobSession/ItemJobSession";
import WORK from "../../assets/image/work.png";
import JOB_OFFICIAL from "../../assets/image/job-official.png";
import JOB_SESSION from "../../assets/image/job-session.png";
import { brand_name, settings } from "../../utils/data";
import ItemBrand from "../../components/ListBrand/ItemBrand";

export default function PageMain() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [job_official, set_job_official] = useState([]);
  const [job_session, set_job_session] = useState([]);
  const dispatch = useDispatch();

  const [get_banner, set_banner] = useState();
  const navigator = useNavigate();
  useEffect(() => {
    getJobSession();
    getBanner();
  }, []);
  const data_session = jobDataLocal.get();
  const data_official = jobDataOfficial.get();
  console.log(data_session);
  const getJobSession = () => {
    jobServices
      .getJobSession()
      .then((res) => {
        set_job_session(res);
        jobDataLocal.set(res);
        dispatch(setJobData(res));
      })
      .catch((err) => {
        console.log(err);
      });
    jobServices
      .getJobOfficial()
      .then((res) => {
        set_job_official(res);
        jobDataOfficial.set(res);
        dispatch(setJobOfficial(res));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBanner = () => {
    jobServices
      .getBanner()
      .then((res) => {
        set_banner(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlelist = (id) => {
    navigator("/list-jobs/" + id);
  };
  return (
    <>
      <MetaTags>
        <title>MVC PRO</title>
      </MetaTags>

      <>
        <Header setIsOpen={setIsOpen} />
        <div className="mt-12 min-w-screen">
          <div className="relative top-0 ">
            <Carousel
              autoPlay
              centerMode={false}
              showArrows={false}
              infiniteLoop={true}
              transitionTime={1500}
              showThumbs={false}
              showStatus={false}
              showIndicators={true}
              preventMovementUntilSwipeScrollTolerance={false}
              className=""
            >
              {get_banner?.map((res, index) => {
                return (
                  <div key={index}>
                    <img src={res} className="w-full" />
                  </div>
                );
              })}
            </Carousel>

            <NavSearch />
          </div>
          <div className="px-5 w-full sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
            <div className="flex">
              <div className="mr-2 flex items-center">
                <img src={WORK} className="w-5" />
              </div>
              <div className="font-regular text-[#3AA3A4] text-[24px]">
                GỢI Ý CÔNG VIỆC
              </div>
            </div>
          </div>
          <div className="px-5 w-full sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
            <div className="mt-5">
              <div className="grid grid-cols-2 sm:grid-cols-2 min-[1024px]:grid-cols-4 sm:gap-3 gap-5">
                <div className="col-span-1">
                  <img
                    src={JOB_OFFICIAL}
                    className="hover:cursor-pointer rounded-lg min-h-full"
                    onClick={() => handlelist(1)}
                  />
                </div>
                {data_official.data === undefined
                  ? ""
                  : data_official?.data?.map((res, index) => (
                      <ItemJobSession item={res} key={index} />
                    ))}
              </div>
              <div className=" font-semibold flex justify-center mt-2 text-[14px] text-[#3AA3A4]">
                <div
                  className="hover:cursor-pointer"
                  onClick={() => handlelist(1)}
                >
                  Xem thêm
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 px-5 w-full sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 sm:gap-3 gap-5">
              <div className="col-span-1">
                <img
                  src={JOB_SESSION}
                  className="hover:cursor-pointer rounded-lg min-h-full"
                  onClick={() => handlelist(2)}
                />
              </div>
              {data_session.data === undefined
                ? ""
                : data_session?.data?.map((res, index) => (
                    <ItemJobSession item={res} key={index} />
                  ))}
            </div>
            <div className="font-semibold flex justify-center mt-2 text-[14px] text-[#3AA3A4]">
              <div
                className=" hover:text-[#e97295]"
                onClick={() => handlelist(2)}
              >
                Xem thêm
              </div>
            </div>
          </div>
          <ChartBenefits />
          <NewsMainList />
          <div className="min-[1700px]:w-[55%] w-[90%] sm:w-[80%] lg:w-[70%] relative content-main mt-8">
            <img
              src={WORK}
              className="w-6 relative left-1/2 -translate-x-1/2"
            />
            <div className="text-center font-regular text-[#3AA3A4] text-[24px]">
              NHÃN HÀNG ĐỐI TÁC
            </div>
            <div className="mt-10 px-5">
              <Slider {...settings}>
                {brand_name?.map((res) => (
                  <>
                    <ItemBrand data={res} />
                  </>
                ))}
              </Slider>
            </div>
          </div>
          <div className="bg-footer mt-16">
            <Footer />
          </div>
        </div>
      </>
    </>
  );
}
