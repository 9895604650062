import FACE from "../../assets/image/facebook-share.png";
import WORK from "../../assets/image/work.png";
import "./ContentRecruitment.css";
import { useEffect, useState } from "react";
import UP_SCROLL from "../../assets/image/up-scroll.png";
import ItemList from "./ItemList";
import { useLocation } from "react-router";
import PopupContent from "./PopupContent";
import ContentPage from "./ContentPage";
import { useParams } from "react-router-dom";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import Header from "../../components/Header";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import { jobServices } from "../../services/apiService/jobServices";

export default function ContentRecruitment() {
  const params = useParams();
  const location = useLocation();
  const [job_list, setJob] = useState();
  const [jobDetail, setJobDetail] = useState([]);
  const [click, setClick] = useState("");
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [click]);
  useEffect(() => {
    if (location.hash !== "") {
      const element = document.getElementById(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const getJobList = (url) => {
    jobServices
      .getJob(url)
      .then((res) => {
        setJob(res);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  const getJobDetail = (id) => {
    jobServices
      .getJobDetails(id)
      .then((res) => {
        getJobList(`?job_type_id=${res?.data?.job_type_id}`);
        setJobDetail(res);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  useEffect(() => {
    if (params?.id !== "") {
      try {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        getJobDetail(params?.id);
      } catch (err) {
        console.log(err);
      }
    }
  }, [params?.id]);
  useEffect(() => {
    if (params?.id !== "") {
      try {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        getJobDetail(params?.id);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);
  return (
    <div className="lg:mt-20 mt-16">
      <Header title={jobDetail?.data?.job_position} />

      <div className="lg:mx-36 2xl:mx-80 sm:mx-32 ">
        <div className="grid lg:grid-cols-6 grid-cols-1 gap-5 mt-5">
          <ContentPage jobDetail={jobDetail} />
          <PopupContent jobDetail={jobDetail} />
        </div>
        <div className="grid lg:grid-cols-6 mx-5">
          <div className="col-span-4">
            <div className="w-20">
              <a
                href="https://www.facebook.com/share.php?u=https://www.facebook.com/mvcpro.vn"
                target="_blank"
              >
                <img src={FACE} className="w-20 mt-5 cursor-pointer" />
              </a>
            </div>
            <div>
              <div className="mt-5 flex flex-col justify-center items-center">
                <div>
                  <img src={WORK} className="w-12 mb-2" />
                </div>
                <div className="font-gothamRegular title-header-staff">
                  CÁC VIỆC KHÁC CÙNG KHỐI
                </div>
              </div>
              <div>
                {job_list &&
                  job_list?.data?.map((data) => (
                    <div key={data?.job_id}>
                      <ItemList
                        data={data}
                        id={params?.id}
                        setClick={setClick}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-span-2"></div>
        </div>
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>

      <ToggleSmooth />
    </div>
  );
}
