import { jobDataOfficial } from "../../services/localService/localService";
import { SET_JOB_OFFICIAL } from "../Constant/reduxConstants";

const initialStateOfficial = {
  userData: jobDataOfficial.get(),
};

export const jobOfficialReducer = (
  state = initialStateOfficial,
  { type, payload }
) => {
  switch (type) {
    case SET_JOB_OFFICIAL:
      return { ...state, userData: payload };
    default:
      return state;
  }
};
