import { Carousel } from "react-responsive-carousel";
import Header from "../../components/HeaderNavBar/HeaderNavBar";
import NavSearch from "../../components/NavSearch/NavSearch";
import { useQuery } from "react-query";
import WORK from "../../assets/image/work.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ItemSearch from "./ItemSearch";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import { MetaTags } from "react-meta-tags";
import { jobServices } from "../../services/apiService/jobServices";

export default function PageSearch() {
  const location = useLocation();
  const [job, setJob] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const banner = useQuery({
    queryKey: ["getbanner"],
    queryFn: () => jobServices.getBanner(),
  });

  const getJob = (data) => {
    jobServices
      .getJob(data)
      .then((res) => {
        setJob(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    const data = location?.search;
    getJob(data);
  }, []);
  useEffect(() => {
    const data = location?.search;
    getJob(data);
  }, [location?.search]);
  return (
    <>
      <MetaTags>
        <title>Tìm kiếm</title>
      </MetaTags>
      <Header setIsOpen={setIsOpen} />
      <div className="mt-12">
        <div className="relative top-0 ">
          <Carousel
            autoPlay
            centerMode={false}
            showArrows={false}
            infiniteLoop={true}
            transitionTime={1500}
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            preventMovementUntilSwipeScrollTolerance={false}
            className=""
          >
            {banner?.data?.data?.map((res, index) => {
              return (
                <div key={index}>
                  <img src={res} className="w-full" />
                </div>
              );
            })}
          </Carousel>

          <NavSearch />
        </div>
      </div>
      <div className="grid lg:grid-cols-4 relative menu-search lg:w-[65%] sm:w-[80%] w-[90%]">
        <div className="col-span-4">
          <div>
            <div className="mt-5 flex flex-col justify-center items-center">
              <div>
                <img src={WORK} className="w-12 mb-2" />
              </div>
              <div className="font-gothamRegular title-header-staff">
                TÌM KIẾM
              </div>
            </div>
          </div>
        </div>

        {job?.map((data) => (
          <div key={data?.job_id} className="col-span-4 lg:col-span-2">
            <ItemSearch data={data} />
          </div>
        ))}
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </>
  );
}
