export const CAMPAIGN_INFO_LOCAL = "CAMPAIGN_INFO_LOCAL";
export const JOB_DATA_LOCAL = "JOB_DATA_LOCAL";
export const JOB_DATA_OFFICIAL = "JOB_DATA_OFFICIAL";
export const PROVINCE_DATA_LOCAL = "USER_DATA_LOCAL";

export const GIFT_DATA_LOCAL = "GIFT_DATA_LOCAL";

export const jobDataLocal = {
  set: (jobData) => {
    let jobDataJson = JSON.stringify(jobData);
    localStorage.setItem(JOB_DATA_LOCAL, jobDataJson);
  },
  get: () => {
    let jobDataJson = localStorage.getItem(JOB_DATA_LOCAL);
    if (jobDataJson) {
      return JSON.parse(jobDataJson);
    } else {
      return {};
    }
  },
};
export const jobDataOfficial = {
  set: (jobOfficial) => {
    let jobOfficialJson = JSON.stringify(jobOfficial);
    localStorage.setItem(JOB_DATA_OFFICIAL, jobOfficialJson);
  },
  get: () => {
    let jobOfficialJson = localStorage.getItem(JOB_DATA_OFFICIAL);
    if (jobOfficialJson) {
      return JSON.parse(jobOfficialJson);
    } else {
      return {};
    }
  },
};
