import StepWizard from "react-step-wizard";
import { Stepper, Step } from "react-form-stepper";
import "../../assets/css/font-button.css";
import { useEffect, useState } from "react";
import "./WizardFormRegister.css";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import OnePage from "../RecruitmentRegister/OnePage";
import { useLocation } from "react-router";
import UP_SCROLL from "../../assets/image/up-scroll.png";
import Two from "../RecruitmentRegister/Two";
import Three from "../RecruitmentRegister/ThreePage";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";

const OneP = (props) => {
  return (
    <div className="mx-9">
      <OnePage {...props} />
    </div>
  );
};

export default function WizardFormInfomation() {
  const [activeStep, setActiveStep] = useState(0);
  const [stepWizard, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };
  const assignUser = (val) => {
    setUser((user) => ({
      ...user,
      ...val,
    }));
  };
  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  useEffect(() => {
    if (location.hash !== "") {
      const element = document.getElementById(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="lg:mx-64 2xl:mx-96">
      <Stepper activeStep={activeStep}>
        <Step
          styleConfig={{
            activeBgColor: "#AADCDC",
            inactiveBgColor: "#EFFFFF",
            inactiveTextColor: "black",
            fontWeight: 500,
            circleFontSize: 20,
            size: 40,
            completedBgColor: "#3AA3A4",
            activeTextColor: "white",
          }}
          label="Thông tin cá nhân"
        />
        <Step
          styleConfig={{
            activeBgColor: "#AADCDC",
            inactiveBgColor: "#EFFFFF",
            activeTextColor: "white",
            fontWeight: 500,
            completedBgColor: "#3AA3A4",
            circleFontSize: 20,
            size: 40,
            inactiveTextColor: "black",
          }}
          label="Kinh nghiệm làm việc"
        />
        <Step
          styleConfig={{
            activeBgColor: "#AADCDC",
            activeTextColor: "white",
            inactiveBgColor: "#EFFFFF",
            fontWeight: 500,
            completedBgColor: "#3AA3A4",
            circleFontSize: 20,
            size: 40,
            inactiveTextColor: "black",
          }}
          label="CV"
        />
      </Stepper>
      <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
        <OneP userCallback={assignUser} />
        <Two user={user} userCallback={assignUser} />
        <Three user={user} />
      </StepWizard>
      <ToggleSmooth />
    </div>
  );
}
