import { jobDataLocal } from "../../services/localService/localService";
import { SET_JOB_DATA } from "../Constant/reduxConstants";

const initialState = {
  userData: jobDataLocal.get(),
};

export const jobReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_JOB_DATA:
      return { ...state, userData: payload };
    default:
      return state;
  }
};
