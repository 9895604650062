import { useEffect, useRef, useState } from "react";
import "../WizardFormInfomation/WizardFormRegister.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import UPLDOAD from "../../assets/image/upload.jpg";
import AVATARUSER from "../../assets/image/avataruser.jpg";
import { Label } from "reactstrap";
import { addDays } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Compressor from "compressorjs";
import uuid from "react-uuid";
import { format } from "date-fns";
import vi from "date-fns/locale/vi";
import { citiesService } from "../../services/apiService/citiesService";
import { apiNodeJS } from "../../services/apiService/apiNode";
import {
  OCR_ENDPOINT,
  URL_API_UPLOAD_GCS,
} from "../../services/apiService/InterfaceExport";
import { formatDate } from "../../utils/format/format";

registerLocale("vi", vi);

const style = {
  width: "92%",
  backgroundColor: "#EEF5FC",
};
export default function One(props) {
  const [cities, setCities] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    const data = {};
    citiesService
      .getCities(data)
      .then((cities) => {
        setCities(cities.data);
      })
      .catch((error) => console.log(error));
  }, []);
  let refInputUpload = useRef(null);

  const [error, setError] = useState("");
  const [provinceId, setProvinceId] = useState(undefined);
  const [customerSex, setCustomerSex] = useState(undefined);
  const [districtID, setDistricID] = useState(undefined);
  const [districData, setDistricData] = useState([]);
  const [wardData, setWardData] = useState([]);
  const [imageFile, setimageFile] = useState(undefined);
  const [linkImage, setLinkImage] = useState(undefined);
  const [waiting, setWaiting] = useState(false);
  const [wardId, setWardId] = useState(undefined);
  const [customerBirthday, setCustomerBirthday] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const [errorBirthday, setErrorBirthday] = useState(null);
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age >= 18 && age <= 60) {
      setErrorBirthday(null);
    } else {
      setErrorBirthday("Vui lòng chọn ngày sinh từ 18 đến 60 tuổi");
    }
    return age;
  }
  const handleChangeBirthday = (date) => {
    getAge(date);
    setCustomerBirthday(date);
    console.log(date);
    console.log(formatDate(date));
  };

  const [errorImage, setErrorImage] = useState("");

  const pushImageToGCS = (data) => {
    console.log(1);
    let formDataGCS = new FormData();
    formDataGCS.append("file", imageFile);
    formDataGCS.append(
      "fileName",
      uuid() +
        "_" +
        format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
        "_" +
        imageFile.name
    );
    formDataGCS.append("ocrBase", OCR_ENDPOINT);

    apiNodeJS
      .postUploadToNode(URL_API_UPLOAD_GCS, formDataGCS)
      .then((res) => {
        console.log(2);

        setLinkImage(res.public_url);
        const image = {
          gender: customerSex,
          city_id: parseInt(provinceId),
          district_id: parseInt(districtID),
          ward_id: parseInt(wardId),
          avatar_url: res.public_url,
          birthday: formatDate(customerBirthday),
        };
        const result = Object.assign(image, data);
        localStorage.setItem("Data_Object", JSON.stringify(result));
        if (result) {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setWaiting(false);
        props.nextStep();
      });
  };

  const [errProvince, setErrorProvince] = useState(undefined);
  const [errDistrict, setErrorDistrict] = useState(undefined);
  const [errWards, setErrorWards] = useState(undefined);
  const [errSex, setSex] = useState(undefined);

  const onSubmit = (data) => {
    let height = parseInt(data.height);
    let weight = parseInt(data.weight);
    data["height"] = height;
    data["weight"] = weight;

    if (
      imageFile &&
      customerBirthday !== null &&
      errorBirthday === null &&
      provinceId !== undefined &&
      districtID !== undefined &&
      wardId !== undefined &&
      customerSex !== undefined
    ) {
      setWaiting(true);
      pushImageToGCS(data);
    } else if (
      customerBirthday === null &&
      imageFile === undefined &&
      provinceId === undefined &&
      customerSex === undefined
    ) {
      window.scrollTo(0, 0);
      setSex("Vui lòng chọn giới tính");
      setErrorBirthday("Vui lòng chọn ngày sinh");
      setErrorImage("Vui lòng chọn ảnh");
      setErrorProvince("Vui lòng chọn tỉnh / thành phố");
      setErrorDistrict("Vui lòng chọn quận / huyện");
      setErrorWards("Vui lòng chọn xã / phường");
    } else if (customerBirthday === null) {
      window.scrollTo(0, 0);
      setErrorBirthday("Vui lòng chọn ngày sinh");
    } else if (imageFile === undefined) {
      setErrorImage("Vui lòng chọn ảnh");
    } else if (customerSex === undefined) {
      window.scrollTo(120, 220);
      setSex("Vui lòng chọn giới tính");
    } else if (provinceId === undefined) {
      window.scrollTo(160, 420);
      setErrorProvince("Vui lòng chọn tỉnh");
      setErrorDistrict("Vui lòng chọn huyện");
      setErrorWards("Vui lòng chọn xã");
    } else if (districtID === undefined) {
      window.scrollTo(380, 420);
      setErrorDistrict("Vui lòng chọn huyện");
      setErrorWards("Vui lòng chọn xã");
    } else if (wardId === undefined) {
      setErrorWards("Vui lòng chọn xã");
    }
  };

  const onChangeSex = (e) => {
    setSex(undefined);
    setCustomerSex(e.target.value);
  };
  useEffect(() => {
    provinceId && getDistrict(provinceId);
  }, [provinceId]);
  const getDistrict = (provinceId) => {
    citiesService
      .getDistrict(+provinceId)
      .then((res) => {
        setDistricData(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    districtID && getWard(districtID);
  }, [districtID]);

  const getWard = (districtID) => {
    citiesService
      .getWards(+districtID)
      .then((res) => {
        setWardData(res.data);
      })
      .catch((error) => console.log(error));
  };

  const handleProvinceChange = (value) => {
    setErrorProvince(undefined);
    setProvinceId(value.target.value);
    setDistricID(undefined);
    setWardId(undefined);
  };
  const handleOnChangeDistrict = (value) => {
    setErrorDistrict(undefined);
    setDistricID(value.target.value);
    setWardId(undefined);
  };

  const handleOnChangeWards = (value) => {
    setErrorWards(undefined);
    setWardId(value.target.value);
  };
  const handleChangeImage = (event) => {
    setWaiting(true);
    console.log("doi");
    try {
      setErrorImage("");
      let fileUploaded = event.target.files[0];
      let iSize = fileUploaded.size / 1024;
      const fileUploadedSize = fileUploaded.size / 1024;
      if (iSize / 1024 > 1) {
        if (iSize / 1024 / 1024 > 1) {
          iSize = Math.round((iSize / 1024 / 1024) * 100) / 100;

          setimageFile(undefined);
          setErrorImage(" vui lòng chọn ảnh hoặc file < 5MB");
        } else {
          iSize = Math.round((iSize / 1024) * 100) / 100;
          if (iSize < 5) {
            if (fileUploadedSize > 20) {
              new Compressor(fileUploaded, {
                quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
              new Compressor(fileUploaded, {
                quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
              new Compressor(fileUploaded, {
                quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
              new Compressor(fileUploaded, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  setimageFile(res);
                },
              });
            } else {
              setimageFile(fileUploaded);
            }
          } else {
            setWaiting(false);

            setimageFile(undefined);
            setErrorImage(" vui lòng chọn ảnh hoặc file < 5MB");
          }
        }
      } else {
        iSize = Math.round(iSize * 100) / 100;
        if (fileUploadedSize > 20) {
          new Compressor(fileUploaded, {
            quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
          new Compressor(fileUploaded, {
            quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
          new Compressor(fileUploaded, {
            quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
          new Compressor(fileUploaded, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            success: (res) => {
              setimageFile(res);
            },
          });
        } else {
          setimageFile(fileUploaded);
        }
      }
      setWaiting(false);
    } catch (error) {
      setWaiting(false);

      console.log(error);
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="background-form-register grid  grid-cols-1 sm:grid-cols-2 gap-5">
        <div className="col-span-1">
          <Label>Họ Và Tên</Label>
          <br />
          <input
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-11/12 border"
            placeholder="Họ và tên "
            {...register("name", {
              required: "Không được để trống",
              pattern: {
                value: /^[\D*]{1,}$/,
                message: "Vui lòng nhập chữ",
              },
            })}
          />
          <span style={{ color: "red" }}>{error}</span>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p
                      key={type}
                      className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]"
                    >
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>
        <div className="col-span-1">
          <Label>Ngày tháng năm sinh</Label>
          <br />
          <DatePicker
            className="datePicker  h-10 mt-2 rounded-lg pl-2 text-[16px] w-11/12 bg-[#EEF5FC]"
            placeholderText="yyyy-mm-dd"
            selected={customerBirthday ? customerBirthday : null}
            locale="vi"
            onChange={(date) => {
              console.log(date);
              handleChangeBirthday(date);
            }}
            dateFormat="yyyy-MM-dd"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            disabledKeyboardNavigation
            dropdownMode="select"
            maxDate={addDays(new Date(), -1)}
            onKeyDown={(e) => {
              console.log(e);
              e.preventDefault();
              return false;
            }}
            onFocus={(e) => e.target.blur()}
          />
          {errorBirthday ? (
            <p className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]">
              {errorBirthday}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-span-1">
          <Label>Chiều cao</Label>
          <br />
          <input
            // type="number"
            // min="1"
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-11/12 border"
            placeholder="Chiều cao"
            {...register("height", {
              required: "Không được để trống",
              pattern: {
                value: /^[+]?([.]\d+|\d+[.]?\d*)$/,
                message: "Vui lòng nhập số dương",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="height"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p
                      key={type}
                      className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]"
                    >
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>
        <div className="col-span-1">
          <Label>Cân nặng</Label>
          <br />
          <input
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-11/12 border"
            placeholder="Cân nặng"
            {...register("weight", {
              required: "Không được để trống",
              pattern: {
                value: /^[+]?([.]\d+|\d+[.]?\d*)$/,
                message: "Vui lòng nhập số dương",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="weight"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p
                      key={type}
                      className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]"
                    >
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>
        <div className="col-span-1">
          <Label className="">Giới tính</Label>
          <br />
          <select
            className="form__sex h-10 mt-2 select-wrapper rounded-lg w-11/12"
            onChange={(e) => onChangeSex(e)}
            style={{
              fontSize: "16px",
              color: "#9DA3AF",
              borderColor: "white",
              paddingLeft: "5px",

              backgroundColor: "#EEF5FC",
            }}
            value={customerSex || ""}
          >
            <option value="" key="sex" disabled selected>
              Giới tính
            </option>
            <option value="male" key="male">
              Nam
            </option>
            <option value="female" key="female">
              Nữ
            </option>
          </select>
          {errSex ? (
            <p className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]">
              {errSex}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-span-1">
          <Label>Số điện thoại</Label>
          <br />
          <input
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-11/12 border"
            placeholder="Số điện thoại"
            type="tel"
            {...register("phone", {
              required: "Không được để trống",
              pattern: {
                value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                message: "Nhập đúng định dạng",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="phone"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p
                      key={type}
                      className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]"
                    >
                      {message}
                    </p>
                  ))
                : null;
            }}
          />{" "}
        </div>
        <div className="col-span-1">
          <Label>Email</Label>
          <br />
          <input
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-11/12 border"
            placeholder="Email"
            {...register("email", {
              required: "Không được để trống",
              pattern: {
                value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                message: "Vui lòng nhập đúng định dạng email",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p
                      key={type}
                      className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]"
                    >
                      {message}
                    </p>
                  ))
                : null;
            }}
          />{" "}
        </div>
      </div>

      <div className=" background-form-register mt-6 grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div className="col-span-1">
          <Label>Tỉnh / Thành phố</Label>
          <br />
          <select
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-10/12 border"
            onChange={(e) => handleProvinceChange(e)}
            style={style}
            value={provinceId || ""}
            name="province"
          >
            <option value="" disabled className="pr-5" selected>
              Nhập tỉnh/thành phố của bạn
            </option>
            {cities?.map((city) => (
              <option id={city.city_id} value={city.city_id} key={city.city_id}>
                {city?.city}
              </option>
            ))}
          </select>

          {errProvince ? (
            <p className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]">
              {errProvince}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-span-1">
          <Label>Quận / Huyện</Label>
          <br />
          <select
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-10/12 border"
            onChange={(e) => {
              handleOnChangeDistrict(e);
            }}
            value={districtID || ""}
            style={style}
            name="district"
          >
            <option value="" style={{ color: "#BDBDBD" }} disabled selected>
              Nhập Quận/Huyện của bạn
            </option>

            {districData?.map((district) => (
              <option
                id={district.district_id}
                value={district.district_id}
                key={district.district_id}
              >
                {district.district}
              </option>
            ))}
          </select>

          {errDistrict ? (
            <p className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]">
              {errDistrict}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-span-1">
          <Label>Phường / Xã</Label>
          <br />
          <select
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-10/12 border"
            onChange={(e) => {
              handleOnChangeWards(e);
            }}
            value={wardId || ""}
            style={style}
            name="wards"
          >
            <option value="" style={{ color: "#BDBDBD" }} disabled selected>
              Nhập Phường / Xã của bạn
            </option>

            {wardData?.map((ward) => (
              <option value={ward.ward_id} id={ward.ward_id} key={ward.ward_id}>
                {ward.ward}
              </option>
            ))}
          </select>

          {errWards ? (
            <p className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]">
              {errWards}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-span-1">
          <Label>Số nhà, tên đường</Label>
          <br />
          <input
            className="pl-2 text-[15px] h-10 z-30 rounded-lg mt-2 w-11/12 border"
            placeholder="Số nhà, tên đường"
            {...register("street", {
              required: "Không được để trống",
              pattern: {
                message: "Vui lòng nhập số",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="street"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p
                      key={type}
                      className="text-[#EC4446] bg-[#ffeff2] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#EC4446] border-[1px] w-11/12 pl-2 text-[12px]"
                    >
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center mt-5">
        <div className="flex flex-col items-center">
          <div>
            {imageFile ? (
              <div>
                <img src={URL.createObjectURL(imageFile)} className="w-60" />
              </div>
            ) : (
              <div>
                <img src={AVATARUSER} className="w-60" />
              </div>
            )}
          </div>
          <div className="text-red-600">{errorImage}</div>
        </div>
        <div className="mt-1 flex">
          <div className="flex items-center" htmlFor="nav-input">
            <img src={UPLDOAD} className="w-10" />
          </div>
          <input
            hidden
            type="file"
            label="hello"
            id="nav-input"
            onChange={(e) => handleChangeImage(e)}
            accept="image/png, image/gif, image/jpeg"
            ref={refInputUpload}
          />

          <Label
            htmlFor="nav-input"
            className="img-avatar italic cursor-pointer "
          >
            Hình ảnh chân dung ( Hỗ trợ *.jpg, *.png, *.jpeg, và &lt; 5MB)
          </Label>
        </div>
      </div>
      {waiting ? (
        <div className="  w-36 h-9 btn-disable bg-[#B0B0B0] mx-12 sm:mx-20 mt-5 float-right lg:mx-36">
          <div className="ml-10">
            <i className="fa fa-spinner fa-spin mt-3"></i>Loading
          </div>
        </div>
      ) : (
        <div className="w-full">
          <input
            type="submit"
            value="Tiếp theo"
            {...props}
            className="absolute hover:cursor-pointer left-1/2 -translate-x-1/2 w-36 h-9 btn-enable float-right  mt-5"
          />
        </div>
      )}
    </form>
  );
}
