import FACE from "../../assets/image/facebook-share.png";
import NEW1 from "../../assets/image/img-share1.jpg";
import WORK from "../../assets/image/work.png";
import TT from "../../assets/image/tt.png";
import NEW2 from "../../assets/image/news-2.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function NewsItem({ data }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleNewDetail = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-detail/" + id);
  };

  return (
    <div className="w-[90%] sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
      <div className="mt-6">
        <div className="flex justify-between">
          <div className="text-gray-500 font-book text-[12px]">
            {data?.time}
          </div>
          <div>
            <div className="w-20">
              <a
                href="https://www.facebook.com/share.php?u=https://www.facebook.com/mvcpro.vn"
                target="_blank"
              >
                <img src={FACE} className="w-20 cursor-pointer" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 p-3 text-[13px] rounded-lg font-book shadow-grid">
        {data.description}
      </div>
      <div className="mt-10">
        {data?.img?.map((res, index) => {
          return (
            <div key={index}>
              <img src={res} className="mt-2 w-full" />
            </div>
          );
        })}
      </div>
      <div className="mt-10">
        {data?.content?.map((res, index) => {
          return (
            <div key={index}>
              <h3 className="font-bold mt-3">{res?.content_title}</h3>
              <p className="mt-1">{res?.content_description}</p>
            </div>
          );
        })}
      </div>

      <div className="flex mt-10">
        <div className="mr-2 flex items-center">
          <img src={WORK} className="w-5" />
        </div>
        <div className="font-regular text-[#3AA3A4] text-[24px]">
          BÀI VIẾT CÙNG CHUYÊN MỤC
        </div>
      </div>

      <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={() =>
            handleNewDetail("mvc-to-chuc-chuong-trinh-huan-luyen-cho-nhan-vien")
          }
        >
          <img src={TT} className="rounded-md repon-img w-full max-h-[194px]" />
          <div className="text-[16px] text-reponsive mt-2 font-regular">
            MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
            tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và chốt
            sale ...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>

        <div
          className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={() =>
            handleNewDetail("mvc-cung-pg-to-chuc-tap-huan-nhan-vien")
          }
        >
          <img
            src={NEW1}
            className="rounded-md w-full repon-img max-h-[194px]"
          />
          <div className="text-[15px] text-reponsive mt-2 font-regular">
            MVCPRO cùng P&G tổ chức đợt tập huấn cho Nhân Viên Bán Hàng kênh
            Siêu thị hiện đại
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            MVCPRO hợp tác cùng P&G tổ chức chương trình tập huấn Nhân Viên Bán
            Hàng kênh Siêu thị hiện đại. Đợt training này diễn ra với sự tham
            gia sôi nổi của hơn 200 nhân viên bán hàng dưới sự chỉ đạo và huấn
            luyện của những ...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>
        <div
          className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
          onClick={() =>
            handleNewDetail("meo-giup-de-dang-trung-tuyen-phong-van")
          }
        >
          <img
            src={NEW2}
            className="rounded-md w-full repon-img max-h-[194px]"
          />
          <div className="text-[15px] text-reponsive mt-2 font-regular">
            MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng
          </div>
          <p className="text-[14px] text-reponsive mt-2 font-book">
            Những Nữ chiến binh bán hàng của MVCPRO đã có những trải nghiệm
            tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và chốt
            sale...
          </p>
          <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
            Xem thêm {">"}
          </div>
        </div>
      </div>
    </div>
  );
}
