export const compareDate = (date) => {
  const date_expried = new Date(date);

  const currentdate = new Date();
  if (currentdate < date_expried) {
    return true;
  } else {
    return false;
  }
};
