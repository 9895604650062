import PopupInfor from "../../PopupInfor/PopupInfor";
import { useState } from "react";
import WORK from "../../../assets/image/work.png";
import ROUND01 from "../../../assets/image/round-06.png";
import ROUND05 from "../../../assets/image/round-07.png";
import ROUND03 from "../../../assets/image/round-08.png";
import ROUND04 from "../../../assets/image/round-09.png";
import ROUND02 from "../../../assets/image/round-10.png";

export default function ChartBenefits() {
  const [open_popup, set_open_popup] = useState(false);
  const [index, set_index] = useState(0);
  const handleOpenPopup = (e) => {
    set_index(e);
    set_open_popup(true);
  };

  return (
    <>
      <div className="min-[1700px]:w-[55%] w-[90%] sm:w-[76%] lg:w-[65%] relative content-main mt-8">
        <img src={WORK} className="w-6 relative left-1/2 -translate-x-1/2" />
        <div className="text-center font-regular text-[#3AA3A4] text-[24px]">
          QUYỀN LỢI KHI TRỞ THÀNH NHÂN VIÊN CỦA MVCPRO
        </div>
      </div>
      <div className="w-[90%] sm:w-[78%] min-[1024px]:w-[80%] min-[1300px]:w-[60%] mt-10 relative content-main ">
        <div className="grid grid-cols-2 md:grid-cols-3">
          <div className="col-span-1 flex justify-center sm:justify-center">
            <div
              className="max-w-[190px] md:max-w-[300px] relative hover:cursor-pointer"
              onClick={() => handleOpenPopup(0)}
            >
              <div className="relative z-10 rounded-[200px] border-1 w-[150px] h-[150px] min-[431px]:w-48 min-[431px]:h-48 lg:w-64 lg:h-64 p-2 border-out1">
                <div className="relative z-50 rounded-[200px] h-full border-1 p-3 sm:p-5 md:p-6 top-1/2 -translate-y-1/2 border-out2">
                  <div>
                    <img
                      src={ROUND01}
                      className="absolute z-10 -top-5 -right-5 max-[430px]:w-6/12 w-5/12 sm:w-6/12 lg:w-5/12"
                    />
                  </div>
                  <div className="relative z-50 bg-white rounded-[200px] h-full p-2 top-1/2 -translate-y-1/2 border-out3">
                    <div className="relative z-30 rounded-[200px] h-full border-1 p-2 top-1/2 -translate-y-1/2 border-out4">
                      <div className="w-full z-30 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[10px] sm:text-[12px] lg:text-[15px] text-center text-[#13a5a5] font-bold">
                        CHẾ ĐỘ
                        <br /> ĐÃI NGỘ
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex justify-center sm:justify-center">
            <div
              onClick={() => handleOpenPopup(1)}
              className="max-w-[190px] md:max-w-[300px] relative hover:cursor-pointer"
            >
              <div className="relative rounded-[200px] z-20 border-1 w-[150px] h-[150px] min-[431px]:w-48 min-[431px]:h-48 lg:w-64 lg:h-64 p-2 border-out1">
                <div className="relative rounded-[200px] z-20 h-full border-1 p-3 sm:p-5 md:p-6 top-1/2 -translate-y-1/2 border-out2">
                  <div>
                    <img
                      src={ROUND02}
                      className="absolute z-10 -bottom-9 min-[431px]:-bottom-10 lg:-bottom-12 left-1/2 -translate-x-1/2  max-[430px]:w-6/12 w-5/12 sm:w-6/12 lg:w-5/12"
                    />
                  </div>
                  <div className="relative bg-white rounded-[200px] z-40 h-full p-2 top-1/2 -translate-y-1/2 border-out3">
                    <div className="relative rounded-[200px] z-40 h-full border-1 p-2 top-1/2 -translate-y-1/2 border-out4">
                      <div className="absolute left-1/2 z-40 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[10px] sm:text-[12px] lg:text-[15px] text-center text-[#13a5a5] font-bold w-full">
                        BẢO HIỂM
                        <br /> SỨC KHỎE
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="max-[767px]:hidden min-[768px]:col-span-1 min-[768px]:flex min-[768px]:justify-center">
            <div onClick={() => handleOpenPopup(2)}>
              <div className="hover:cursor-pointer relative rounded-[200px] z-0 border-1 w-[150px] h-[150px] min-[431px]:w-48 min-[431px]:h-48 lg:w-64 lg:h-64 p-2 border-out1">
                <div className="relative rounded-[200px] z-0 h-full border-1 p-3 sm:p-5 md:p-6 top-1/2 -translate-y-1/2 border-out2">
                  <div>
                    <img
                      src={ROUND03}
                      className="absolute z-10 -top-5 -left-5 max-[430px]:w-6/12 w-5/12 sm:w-6/12 lg:w-5/12"
                    />
                  </div>
                  <div className="relative bg-white z-20 rounded-[200px] h-full p-2 top-1/2 -translate-y-1/2 border-out3">
                    <div className="relative z-20 rounded-[200px] h-full border-1 p-2 top-1/2 -translate-y-1/2 border-out4">
                      <div className="absolute z-20 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[10px] sm:text-[12px] lg:text-[15px] text-center text-[#13a5a5] font-bold w-full">
                        THĂNG TIẾN
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-3">
          <div>
            <div
              onClick={() => handleOpenPopup(2)}
              className=" md:hidden block relative hover:cursor-pointer"
            >
              <div className="relative rounded-[200px] z-0 border-1 w-[150px] h-[150px] min-[431px]:w-48 min-[431px]:h-48 lg:w-64 lg:h-64 p-2 border-out1">
                <div className="relative rounded-[200px] z-0 h-full border-1 p-3 sm:p-5 md:p-6 top-1/2 -translate-y-1/2 border-out2">
                  <div>
                    <img
                      src={ROUND03}
                      className="absolute z-10 -top-5 -left-5 max-[430px]:w-6/12 w-5/12 sm:w-6/12 lg:w-5/12"
                    />
                  </div>
                  <div className="relative z-20 bg-white rounded-[200px] h-full p-2 top-1/2 -translate-y-1/2 border-out3">
                    <div className="relative z-20 rounded-[200px] h-full border-1 p-2 top-1/2 -translate-y-1/2 border-out4">
                      <div className="absolute z-20 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[10px] sm:text-[12px] lg:text-[15px] text-center text-[#13a5a5] font-bold w-full">
                        THĂNG TIẾN
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3">
          <div className="md:mr-[6%]  flex justify-center md:justify-end col-span-1">
            <div
              onClick={() => handleOpenPopup(3)}
              className=" min-[1024px]:max-w-[280px] relative hover:cursor-pointer"
            >
              <div className="relative rounded-[200px] border-1 w-[150px] h-[150px] min-[431px]:w-48 min-[431px]:h-48 lg:w-64 lg:h-64 p-2 border-out1">
                <div className="relative rounded-[200px] h-full border-1 p-3 sm:p-5 md:p-6 top-1/2 -translate-y-1/2 border-out2">
                  <div>
                    <img
                      src={ROUND04}
                      className="absolute z-10 -bottom-5 -left-5 max-[430px]:w-6/12 w-5/12 sm:w-6/12 lg:w-5/12"
                    />
                  </div>
                  <div className="relative bg-white z-20 rounded-[200px] h-full p-2 top-1/2 -translate-y-1/2 border-out3">
                    <div className="relative rounded-[200px] h-full border-1 p-2 top-1/2 -translate-y-1/2 border-out4">
                      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[10px] sm:text-[12px] lg:text-[15px] text-center text-[#13a5a5] font-bold w-full">
                        ĐÀO TẠO
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:ml-[6%] flex justify-center md:justify-start col-span-1">
            <div
              onClick={() => handleOpenPopup(4)}
              className=" min-[1024px]:max-w-[280px] relative hover:cursor-pointer"
            >
              <div className="relative rounded-[200px] border-1 w-[150px] h-[150px] min-[431px]:w-48 min-[431px]:h-48 lg:w-64 lg:h-64 p-2 border-out1">
                <div className="relative rounded-[200px] h-full border-1 p-3 sm:p-5 md:p-6 top-1/2 -translate-y-1/2 border-out2">
                  <div>
                    <img
                      src={ROUND05}
                      className="absolute z-10 -bottom-5 -right-5 max-[430px]:w-6/12 w-5/12 sm:w-6/12 lg:w-5/12"
                    />
                  </div>
                  <div className="relative bg-white z-20 rounded-[200px] h-full p-2 top-1/2 -translate-y-1/2 border-out3">
                    <div className="relative rounded-[200px] h-full border-1 p-2 top-1/2 -translate-y-1/2 border-out4">
                      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[10px] sm:text-[12px] lg:text-[15px] text-center text-[#13a5a5] font-bold w-full">
                        HOẠT ĐÔNG
                        <br />
                        NGOẠI KHÓA
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open_popup ? (
        <PopupInfor set_open_popup={set_open_popup} index={index} />
      ) : null}
    </>
  );
}
