import { useEffect, useState } from "react";
import UP_SCROLL from "../../../assets/image/up-scroll.png";
import { useLocation } from "react-router-dom";

export default function ToggleSmooth() {
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (location.hash !== "") {
      const element = document.getElementById(location.hash);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      {visible ? (
        <div className="scroll-ocr hover:cursor-pointer" onClick={scrollToTop}>
          <img src={UP_SCROLL} className="anima-scroll-top w-7 m-1 z-50" />
        </div>
      ) : null}
    </>
  );
}
