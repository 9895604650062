import { useEffect, useState } from "react";
import contact from "../../assets/image/contact-big.png";
import contactm from "../../assets/image/contact-m.png";
import WORK from "../../assets/image/work.png";
import DN from "../../assets/image/dn.png";
import PP from "../../assets/image/pp.png";
import GL from "../../assets/image/gl.png";
import HS from "../../assets/image/hs.png";
import contacticon from "../../assets/image/profile.png";
import EMAIL from "../../assets/image/mail-z.png";
import PHONE from "../../assets/image/phone-z.png";
import CHAT from "../../assets/image/chat-z.png";
import Slider from "react-slick";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import SUCCESS from "../../assets/image/success.png";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import { MetaTags } from "react-meta-tags";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import { jobServices } from "../../services/apiService/jobServices";
import { brand_name, settings } from "../../utils/data";
import ItemBrand from "../../components/ListBrand/ItemBrand";

export default function Contact() {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [status_success, setStatusSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    criteriaMode: "all",
  });

  const onSubmit = (data) => {
    jobServices
      .getContact(data)
      .then((res) => {
        if (res.result.meta.status_code === 200) {
          reset();
          setIsOpen(true);
          setStatusSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRedirectHome = () => {
    navigate("/");
  };

  return (
    <>
      <MetaTags>
        <title>Liên Hệ</title>
      </MetaTags>
      {modalIsOpen ? (
        <div
          className="fixed z-10 overflow-y-auto top-0 w-full left-0 "
          id="modal"
        >
          <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div
                className="absolute inset-0 bg-gray-900 opacity-70"
                onClick={() => navigate("/")}
              />
              <div className="content-popup-condition">
                <div className=" content-popupgame p-3 rounded-sm">
                  <div id="scroll-body">
                    <div className="relative flex flex-col items-center border-solid mt-6 scroll-body">
                      <div className="pyro ">
                        <div className="before"></div>
                        <div className="after"></div>
                      </div>
                      <div className="font-gothamRegular text-[30px] text-[#38c44f] bg-popup-success text-center">
                        Thành Công
                      </div>
                      <div className="mt-12 font-gothamRegular text-[24px] text-center">
                        {status_success ? (
                          <div>
                            <img src={SUCCESS} />
                          </div>
                        ) : null}
                      </div>
                      <button
                        className="hover:cursor-pointer mt-12 mb-10 bg-footer p-2 rounded-md text-white"
                        onClick={handleRedirectHome}
                      >
                        Quay về trang chủ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="mt-28 w-[90%] sm:w-[80%] min-[1024px]:w-[90%] min-[1300px]:w-[73%] relative content-main">
        <div className="grid grid-cols-1 md:grid-cols-9 gap-5">
          <div className="col-span-1 md:col-span-4">
            <div className="shadow-grid p-5 rounded-lg">
              <div className="font-regular text-[18px] text-[#13a5a5] text-center">
                Gửi thông tin liên hệ
              </div>
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mt-5 items-center">
                  <label>
                    <img src={contacticon} />
                  </label>
                  <input
                    type="text"
                    className=" w-full h-10 mr-12 ml-5 form-control border-b-[1px]"
                    placeholder="Nhập họ tên"
                    {...register("name", {
                      required: "Không được để trống",
                      pattern: {
                        value: /^[\D*]{1,}$/,
                        message: "Vui lòng nhập tên",
                      },
                    })}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="text-[#EC4446] pl-32 pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl text-[12px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
                <div className="flex mt-5 items-center">
                  <label>
                    <img src={EMAIL} />
                  </label>
                  <input
                    type="text"
                    className=" w-full h-10 mr-12 ml-5 form-control border-b-[1px]"
                    placeholder="Email của bạn"
                    {...register("email", {
                      required: "Không được để trống",
                      pattern: {
                        value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        message: "Vui lòng nhập email",
                      },
                    })}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="text-[#EC4446] pl-32 pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl text-[12px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
                <div className="flex mt-5 items-center">
                  <label>
                    <img src={PHONE} />
                  </label>
                  <input
                    type="text"
                    className=" w-full h-10 mr-12 ml-5 form-control border-b-[1px]"
                    placeholder="Số điện thoại"
                    {...register("phone", {
                      required: "Không được để trống",
                      pattern: {
                        value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                        message: "Vui lòng nhập số điện thoại",
                      },
                    })}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="text-[#EC4446] pl-32 pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl text-[12px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
                <div className="flex mt-5 items-center">
                  <label>
                    <img src={CHAT} />
                  </label>
                  <input
                    type="text"
                    className=" w-full h-10 mr-12 ml-5 form-control border-b-[1px]"
                    placeholder="Nội dung liên hệ"
                    {...register("description", {})}
                  />
                </div>

                <div className="flex justify-center">
                  <button
                    className="mt-14 mb-5  btn-ut bg-[#3AA3A4] text-[15px] px-6 py-2 text-white rounded-3xl"
                    type="submit"
                  >
                    Gửi liên hệ
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-span-1 md:col-span-5">
            <img src={contact} className="lg:block hidden" />
            <img src={contactm} className="block lg:hidden" />
          </div>
        </div>
      </div>
      <div className="min-[1700px]:w-[55%] w-[90%] sm:w-[80%] lg:w-[70%] relative content-main mt-8">
        <img src={WORK} className="w-6 relative left-1/2 -translate-x-1/2" />
        <div className="text-center font-regular text-[#3AA3A4] text-[24px]">
          NHÃN HÀNG ĐỐI TÁC
        </div>
        <div className="mt-10">
          <Slider {...settings}>
            {brand_name?.map((res) => (
              <>
                <ItemBrand data={res} />
              </>
            ))}
          </Slider>
        </div>
      </div>

      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </>
  );
}
